





























import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import utils from '@/utils/util.ts'
import casePass from '@/components/case/pass.vue'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    casePass
  },
  mixins: [globalMixins]
})
export default class ATSViewsCasePass extends Vue<globalMixins> {

  @Getter('total') total: number
  @Getter('items') items: Object[]
  @Getter('fields') fields: any[]
  @Action('caseList') caseList: Function
  @Action('caseReview') caseReview: Function
  @Action('caseReviewBatch') caseReviewBatch: Function

  loading: boolean = false
  case_ids: any[] = []
  query: any = {
    status: 'approved',
    keyword: ''
  }
  pagination: any = {
    page: 1,
    size: 10
  }
  project: any = {}
  mounted () {
    this.project = JSON.parse(localStorage.getItem("projects"))
    this.query['projectId'] = this.project.id
    this.doSearch()
  }
  handleSelectionChange (ids) {
    this.case_ids = ids
  }
  // 批量
  turnToReject () {
    if (this.case_ids.length === 0) {
      this.errorMsg('请选择数据进行操作!')
      return
    }
    let params = {
      case_ids: this.case_ids,
      operation: 'reject',
      projectId: this.project.id
    }
    this.$prompt('请输入驳回原因(必填)', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /\S/,
      inputErrorMessage: '驳回原因为必填'
    }).then(value => {
      params = Object.assign({}, params, {remark: value['value']})
      this.caseReviewBatch({ params }).then(() => {
        this.successMsg('已批量驳回!')
        this.doSearch()
      }).catch(err => {
        this.errorMsg('请稍后重试!')
      })
    })
  }
  doKeywordSearch () {
    this.pagination.page = 1
    this.doSearch()
  }
  onPagerSizeChange (val: number) {
    this.pagination.size = val
    this.doSearch()
  }
  onPagerCurrentChange (val: number) {
    this.pagination.page = val
    this.doSearch()
  }
  doSearch () {
    this.loading = true
    let pagination = utils.pager(this.pagination.page, this.pagination.size)
    let params = Object.assign({}, this.query, {limit: pagination.limit}, {offset: pagination.offset}) 
    if (this.query.keyword === '') {
      delete params.keyword
    }
    this.caseList({ params }).then(() => {
      setTimeout(() => {
        this.loading = false
      }, 200)
    })
  }
}
